body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading {
  width: 44px;
  margin: 100px auto;
  aspect-ratio: 2;
  --_g: no-repeat
    radial-gradient(circle closest-side, rgb(126, 144, 160) 90%, #ffffff00);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: d3 1s infinite linear;
}
@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  height: 100vh;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  background-color: rgb(126, 144, 160);
  color: aliceblue;
  font-size: 32px;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 70px;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}
/* ----- MODAL STYLE ----- */

.modal-body {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  font-size: 24px;
}

.modal-content {
  border-radius: 0;
  border: none;
}
.modal-content span {
  color: rgb(81, 81, 81);
}

.modal-header {
  color: aliceblue;
  background-color: rgb(126, 144, 160);
}

.modal-buttons {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  position: relative;
  bottom: 100px;
}

.modal-buttons .btn {
  display: block;
  width: 100%;
  margin-top: 50px;
  padding: 10px;
}

.btn.btn-red {
  margin-top: 30px;
  background-color: rgb(217, 0, 0);
}
.btn.btn-red:hover {
  background-color: rgb(157, 0, 0);
}

.hidden {
  display: none;
}

.checkbox {
  background-color: aliceblue !important;
  border: 0.1px solid grey !important;
  padding: 10px;
  margin: 10px;
}

.navbar-expand .navbar-nav .nav-link {
  background-color: black;
  border-radius: 10px;
}

.home,
.add-edit,
.listing {
  min-height: 100vh;
  background-color: rgb(224, 242, 255);
  padding-top: 50px;
}

.home {
}

.add-edit h1 {
  color: rgb(126, 144, 160);
  margin: auto;
  width: fit-content;
}

.add-edit .container {
  width: 60vw;
  min-height: 65vh;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  border: 0.2px solid rgb(191, 190, 190);
  margin: 50px auto;
  padding: 50px 10px;
  display: flex;
  position: relative;
}

.add-edit .btn-toggle {
  background-color: rgb(126, 144, 160);
  border: none;
  max-width: fit-content;
  min-width: fit-content;
}
.add-edit .btn-toggle:hover {
  background-color: #000 !important;
}
.add-edit .btn-toggle:focus {
  background-color: #000 !important;
}
.add-edit .btn-dark {
  --bs-btn-active-bg: black !important;
  --bs-btn-focus-shadow-rgb: none !important;
  min-width: 63px;
}

.add-edit .container .btn-submit {
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  bottom: 0;
  text-align: center !important;
  width: fit-content;
  margin: 20px auto !important;
  display: block;
  margin: auto;
}

.add-edit .row {
  margin: 20px 80px 10px 80px;
  max-width: 80%;
}

.row p {
  margin: 0px;
}

.row .pad {
  margin-top: 20px;
}

.container-2 {
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.container-2 img {
  width: 300px;
  padding: 20px;
}
.form-control:focus {
  box-shadow: none !important;
  border: 0.2px solid grey !important;
}

.filters {
  background-color: rgb(126, 144, 160);
  margin: 20px auto;
  padding: 50px 10px;
  width: 83vw;
  height: 138px;
  display: flex;
  justify-content: space-evenly;
  border-radius: 10px;
}

.filter {
  min-width: 10vw;
}

.btn-search {
  width: 10vw;
}

.btn-add {
  width: 10vw;
  max-height: 40px;
  background-color: black !important;
  border: none !important;
}

.css-1s2u09g-control {
  border-radius: 25px !important;
  background-color: aliceblue !important;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.items .link {
}

.item {
  max-width: 300px;
  border-radius: 10px;
  margin: 50px;
  background-color: rgb(126, 144, 160);
  color: azure;
}
.item:hover {
  cursor: pointer;
}

.item .image {
  max-width: 300px;
  max-height: 200px;
  min-width: 300px;
  min-height: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: aliceblue;
}

.item .details {
  min-height: 200px;
  max-height: 200px;
  padding: 20px;
  position: relative;
}

.details > h3 {
  font-weight: 700;
}

.item .details h4 {
  display: inline-block;
  color: rgb(88, 0, 202);
  font-weight: 700;
}

.listing-modal {
}

.modal-img {
  min-width: 500px;
  max-width: 500px;
  min-height: 333.3px;
  max-height: 333.3px;
  overflow: hidden;
}

.favorite {
  position: absolute;
  top: 15px;
  right: 40px;
  border: 1px solid azure;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.extra {
  display: flex;
  justify-content: space-evenly;
}

.footer {
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  color: rgb(127, 140, 153);
  font-size: 14px;
  font-weight: 500;
  background-color: #000;
}

.footer a {
  color: inherit;
  text-decoration: none;
}
.footer a:hover {
  color: inherit;
}

.footer a span#c {
  color: rgb(115, 124, 133);
  transition: all 0.2s ease-in-out;
}

.footer a:hover span#c {
  color: rgb(155, 2, 2);
}

@media screen and (min-width: 1200px) and (max-width: 1920px) {
  .add-edit .container {
    width: 80vw;
  }
  .row .row-2 {
    width: 70% !important;
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .filters {
    flex-direction: column;
    justify-content: start;
    min-height: 500px;
    padding: 20px 0px;
  }

  .filter,
  .btn.btn-search,
  .btn.btn-add {
    margin: 20px auto;
    max-width: 50vw;
    min-width: 50vw;
  }

  .add-edit .container {
    width: 100vw;
    flex-direction: column;
  }

  .add-edit .row {
    margin: 20px;
    max-width: 100%;
  }

  .modal.right .modal-dialog {
    width: 100vw;
  }
}
